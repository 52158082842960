import React from "react";
import { graphql } from "gatsby";
import PortfolioPageLayout from "../components/PortfolioPageLayout";

export default function PortfolioPage({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <PortfolioPageLayout
      pageTitle={frontmatter.work_title}
      videoSrcURL={frontmatter.video}
      postBody={html}
    />
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        project_title
        work_title
        video
      }
    }
  }
`;
