import React from "react";
import "../css/styles.css";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <footer>
      <StaticImage
        className="orb-footer"
        src="../data/images/orb-small.png"
        alt="A grey and white orb"
        placeholder="transparent" //placeholder property casuses MaxListenersExceededWarning https://github.com/gatsbyjs/gatsby/issues/34795
      />
    </footer>
  );
};

export default Footer;
