import React from "react";
import "../css/styles.css";

const HeroVideo = ({ videoSrcURL, videoTitle }) => (
  <div className="video-container">
    <iframe
      src={videoSrcURL}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
      title={videoTitle}
    ></iframe>
  </div>
);
export default HeroVideo;
