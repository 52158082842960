import * as React from "react";
import Footer from "./Footer";
import Header from "./Header";
import HeroVideo from "./HeroVideo";

const PortfolioPageLayout = ({ pageTitle, videoSrcURL, postBody }) => {
  return (
    <div>
      <title>{pageTitle}</title>
      <Header />
      <HeroVideo videoSrcURL={videoSrcURL} videoTitle={pageTitle} />
      <div className="portfolio-text-container">
        <h2>{pageTitle}</h2>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: postBody }}
        />
      </div>
      <Footer />
    </div>
  );
};
export default PortfolioPageLayout;
